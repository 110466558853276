const tableOfContents = () => {
    const tocButton = document.getElementById('toc-button')
    const tocList = document.getElementById('toc-list')

    tocButton.addEventListener('click', function () {
        tocButton.classList.toggle('toc__title--open')
        tocList.classList.toggle('toc__list--hidden')
    })
}

export default tableOfContents
